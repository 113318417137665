import { Box, Grid, TextField } from '@material-ui/core';
import { TextInput, useRecordContext, useResourceContext } from 'react-admin';

import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';
import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';
import FileInputPreview from '@components/input/FileInputPreview';
import VideoImageInput from '@components/input/VideoImageInput';
import PriceInput from '@components/input/PriceInput';
import { StatusInput } from '@components/resource/errand/input/StatusInput';

import PhysicalStoreSaleInput from './input/PhysicalStoreSaleInput';
import SyncToSelloInput from './input/SyncToSelloInput';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { Product } from '@js/interfaces/product';

type Props = { resource?: string; record?: Product };

const SimpleForm = (props: Props) => {
    const resource = useResourceContext(props);
    const getFieldLabel = useTranslateResourceField('reclamations');
    const record = useRecordContext(props);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SyncToSelloInput />
            </Grid>
            <Grid item xs={12}>
                <Box mt={1} display="flex" gridGap="20px">
                    <TextField
                        size="small"
                        label={getFieldLabel('quantity')}
                        value={record?.quantity ?? ''}
                        margin="dense"
                        variant="filled"
                        disabled
                    />
                    <PhysicalStoreSaleInput />
                    <StatusInput source="status" />
                </Box>
            </Grid>

            <Grid item xs={12} md={4}>
                <TextInput label={getFieldLabel('title')} source="title" resource={resource} fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
                <PriceInput
                    label={getFieldLabel('insurancePrice')}
                    source="insurancePrice"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <PriceInput
                    label={getFieldLabel('outgoingPrice')}
                    source="outgoingPrice"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextInput
                    label={getFieldLabel('supplierSerialNumber')}
                    source="supplierSerialNumber"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextInput label={getFieldLabel('ean')} source="ean" resource={resource} fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
                <AutoCompleteGuesserInput
                    label={getFieldLabel('location')}
                    source="location"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <AutoCompleteGuesserInput
                    label={getFieldLabel('manufacturer')}
                    source="manufacturer"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <AutoCompleteGuesserInput label={getFieldLabel('model')} source="model" resource={resource} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
                <AutoCompleteGuesserInput label={getFieldLabel('grade')} source="grade" resource={resource} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
                <ReferenceRecursiveSelectInput
                    label={getFieldLabel('category')}
                    source="category"
                    resource={resource}
                    filter={{ entrypoint: true }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    multiline
                    label={getFieldLabel('classificationOutComment')}
                    source="classificationOutComment"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <VideoImageInput source="images" multiple accept="image/*, application/pdf, video/*">
                    <FileInputPreview reference="reclamation_media_objects" />
                </VideoImageInput>
            </Grid>
        </Grid>
    );
};

export default SimpleForm;

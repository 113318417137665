import AssessmentIcon from '@material-ui/icons/Assessment';
import ErrandsExportList from './ErrandsExportList';

export default {
    icon: AssessmentIcon,
    list: ErrandsExportList,
    show: null,
    create: null,
    edit: null,
    options: {
        alwaysHideFields: [],
    },
};

import { lazy, Suspense, useState } from 'react';
import {
    AutocompleteInput,
    LinearProgress,
    Record as RaRecord,
    ReferenceInput,
    ReferenceInputProps,
    useTranslate,
} from 'react-admin';
import { useField } from 'react-final-form';
import { IconButton, InputAdornment } from '@material-ui/core';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';

import DialogCodeScanner from '@components/camera/DialogCodeScanner';

import useInputAutoValidator from '@js/hooks/useInputAutoValidator';
import useInputReferenceField from '@js/hooks/useInputReferenceField';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { useEntrypoint } from '@js/context/AppConfigContext';
import { post } from '@js/request/apiRequest';
import { Iri } from '@js/interfaces/ApiRecord';

const BarcodeScanner = lazy(() => import('@components/camera/BarcodeScanner'));

const BarcodeScannerReferenceInput = ({
    onCreate,
    ...props
}: SafeOmit<ReferenceInputProps, 'reference' | 'children'> & {
    onCreate?: (value?: string) => Promise<RaRecord>;
}) => {
    const [open, setOpen] = useState(false);
    const validate = useInputAutoValidator(props);
    const { reference, fieldName } = useInputReferenceField(props);

    return (
        <>
            <ReferenceInput
                reference={reference}
                sort={{ field: fieldName, order: 'ASC' }}
                allowEmpty={false}
                validate={validate}
                {...props}
            >
                <AutocompleteInput
                    optionText={fieldName}
                    options={{
                        InputProps: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton disableRipple onClick={() => setOpen(true)}>
                                        <HorizontalSplitIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                    }}
                    matchSuggestions={defaultMatchSuggestion}
                    onCreate={onCreate}
                    fullWidth
                    resettable
                />
            </ReferenceInput>
            <BarcodeScannerDialog
                source={props.source}
                open={open}
                onClose={() => setOpen(false)}
                reference={reference}
                fieldName={fieldName}
            />
        </>
    );
};

const defaultMatchSuggestion = () => true;

const BarcodeScannerDialog = ({
    open,
    source,
    onClose,
    reference,
    fieldName,
}: {
    open: boolean;
    onClose: () => void;
    source: string;
    reference: string;
    fieldName: string;
}) => {
    const translate = useTranslate();
    const entrypoint = useEntrypoint();
    const notifyFailure = useNotifyHttpError();
    const { input } = useField(source);
    const [loading, setLoading] = useState(false);

    const handleSuccessScan = async (result: string) => {
        setLoading(true);

        try {
            const record = await post<{ '@id': Iri; [key: string]: any }>(`${entrypoint}/${reference}/find_or_create`, {
                body: JSON.stringify({ [fieldName]: result }),
            });

            input.onChange(record['@id']);
            onClose();
        } catch (error) {
            notifyFailure(error);
            setLoading(false);
        }
    };

    return (
        <DialogCodeScanner
            open={open}
            onClose={onClose}
            title={translate('app.filter.fields.scanBarcode.label')}
            loading={loading}
            onExited={() => setLoading(false)}
        >
            {!loading && (
                <Suspense fallback={<LinearProgress />}>
                    <BarcodeScanner onSuccess={handleSuccessScan} />
                </Suspense>
            )}
        </DialogCodeScanner>
    );
};

export default BarcodeScannerReferenceInput;

import { Box, Card, Typography } from '@material-ui/core';
import {
    Datagrid,
    ListBase,
    NumberField,
    Pagination,
    ReferenceManyField,
    ResourceContextProvider,
    useRecordContext,
    useTranslate,
} from 'react-admin';

import TranslatableTextField from '@components/field/TranslatableTextField';
import SecondsTimeField from '@components/field/SecondsTimeField';
import PriceField from '@components/field/PriceField';
import AutoHidePagination from '@components/list/AutoHidePagination';
import AlertEmptyResource from '@components/list/AlertEmptyResource';

import { SaleChannelsDatagrid } from './SaleChannelsStatistics';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { Locale } from '@js/context/AppConfigContext';
import { Iri } from '@js/interfaces/ApiRecord';
import { Filter } from './EconomicalDetailed';

type Props = {
    filter?: Filter;
};

type CategoryEconomicalStatisticItem = {
    id: number;
    '@id': Iri;
    averageSalesPrice: number;
    totalProcessingTime: number;
    totalErrandProcessingTime: number;
    totalReclamationProcessingTime: number;
    translatableTitle: Record<Locale, string | null>;
    totalReclamationQuantity: number;
    category: Iri;
};

const CategoryEconomicalStatisticList = ({ filter = {} }: Props) => {
    const getFieldLabel = useTranslateResourceField();
    const translate = useTranslate();
    const getResourceLabel = useTranslateResourceField();

    const resource = 'category_economical_statistic_items';
    const processingTimeLabel = getFieldLabel('processingTimes', 'reclamations');

    const { store, ...restFilter } = filter;

    return (
        <>
            <Typography variant="h6">{getFieldLabel('category', 'reclamations')}</Typography>
            <Box mt={2}>
                <ResourceContextProvider value={resource}>
                    <ListBase
                        resource={resource}
                        basePath={`/${resource}`}
                        filter={{ ...restFilter, 'reclamations.errand.store': store }}
                        sort={{ field: 'averageSalesPrice', order: 'DESC' }}
                    >
                        <Card>
                            <Datagrid expand={<ExpandedRow filter={filter} />}>
                                <TranslatableTextField
                                    source="translatableTitle"
                                    sortable={false}
                                    label={getFieldLabel('title', 'categories')}
                                />
                                <NumberField
                                    label={getResourceLabel('reclamations')}
                                    source="totalReclamationQuantity"
                                />
                                <PriceField
                                    source="averageSalesPrice"
                                    label={translate('app.label.average_sales_price')}
                                    formatCents={false}
                                />
                                <SecondsTimeField
                                    source="totalProcessingTime"
                                    label={processingTimeLabel}
                                    sortable={false}
                                />
                                <SecondsTimeField
                                    source="totalErrandProcessingTime"
                                    label={`${getResourceLabel('errands')} ${processingTimeLabel}`}
                                />
                                <SecondsTimeField
                                    source="totalReclamationProcessingTime"
                                    label={`${getResourceLabel('reclamations')} ${processingTimeLabel}`}
                                />
                            </Datagrid>
                            <Pagination />
                        </Card>
                    </ListBase>
                </ResourceContextProvider>
            </Box>
        </>
    );
};

const ExpandedRow = ({ filter = {}, ...props }: { record?: CategoryEconomicalStatisticItem; filter?: Filter }) => {
    const record = useRecordContext(props);
    const { store, ...restFilter } = filter;

    return (
        <ReferenceManyField
            record={{ id: record?.category }}
            perPage={10}
            reference="sale_channels"
            target="reclamation.category"
            pagination={<AutoHidePagination />}
            sort={{ field: 'totalSalesSEK', order: 'desc' }}
            filter={{ ...restFilter, 'reclamation.errand.store': store }}
        >
            <SaleChannelsDatagrid {...props} empty={<AlertEmptyResource mb={2} />} />
        </ReferenceManyField>
    );
};
export default CategoryEconomicalStatisticList;

import { FC, useState } from 'react';
import {
    Datagrid,
    DateField,
    minValue,
    NumberInput,
    ReferenceManyField,
    ReferenceManyFieldProps,
    required,
    TextField,
    TextInput,
    useListContext,
    useNotify,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Create';

import AutoHidePagination from '@components/list/AutoHidePagination';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import RecordPriceField from '@components/field/RecordPriceField';
import IconButton from '@components/button/IconButton';
import UpdateDialogForm from '@components/form/UpdateDialogForm';
import PassthroughProps from '@components/PassthroughProps';
import DeleteIconButton from '@components/button/DeleteIconButton';

import decimal from '@js/validator/decimal';

import { ExternalSale } from '@js/interfaces/externalSale';
import useSalesContextFilter from '@components/resource/reclamation/useSalesContextFilter';

type Props = Omit<ReferenceManyFieldProps, 'children' | 'reference'>;

const ExternalSalesTab: FC<Props> = (props) => {
    const { filter, warning } = useSalesContextFilter();

    return (
        <ReferenceManyField
            {...props}
            sort={{ field: 'soldAt', order: 'desc' }}
            perPage={10}
            reference="external_sales"
            pagination={<AutoHidePagination />}
            filter={filter}
        >
            <PassthroughProps>
                {(props) => (
                    <>
                        {warning}
                        <Datagrid {...props} empty={<AlertEmptyResource mb={2} />}>
                            <DateField source="soldAt" showTime />
                            <RecordPriceField source="salesPrice" currencyField="currency" />
                            <TextField source="quantity" sortable={false} />
                            <TextField source="channel" sortable={false} />
                            <RowActions />
                        </Datagrid>
                    </>
                )}
            </PassthroughProps>
        </ReferenceManyField>
    );
};

const RowActions = (props: { addLabel?: boolean; sortable?: boolean; record?: ExternalSale; resource?: string }) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const translate = useTranslate();
    const { refetch } = useListContext();
    const notify = useNotify();

    if (!record) return null;

    const isBundleSale = record?.bundle;
    let disabledReason;

    if (isBundleSale) {
        disabledReason = 'resources.products.message.edit_bundle_item_sale_prohibited';
    }

    return (
        <>
            <Box display="flex" justifyContent="end">
                <IconButton label="ra.action.edit" onClick={() => setOpen(true)} disabledReason={disabledReason}>
                    <EditIcon />
                </IconButton>
                <DeleteIconButton
                    disabledReason={disabledReason}
                    onSuccess={() => {
                        notify('ra.notification.deleted', {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                            undoable: false,
                        });
                        refetch();
                    }}
                />
            </Box>
            {!isBundleSale && (
                <UpdateDialogForm
                    title="ra.action.edit"
                    record={record}
                    resource="external_sales"
                    open={open}
                    onClose={() => setOpen(false)}
                    mutationMode="pessimistic"
                    initialValues={{
                        salesPricePerItem: record.salesPricePerItem,
                        channel: record.channel,
                        quantity: record.quantity,
                    }}
                >
                    <NumberInput
                        source="salesPricePerItem"
                        label={translate('resources.external_sales.fields.salesPrice')}
                        step="0.01"
                        validate={[decimal(), required(), minValue(1)]}
                        fullWidth
                        resource={resource}
                        helperText={translate('app.form.helperText.price_per_item_input')}
                    />
                    <NumberInput
                        source="quantity"
                        step="1"
                        validate={[required(), minValue(1)]}
                        fullWidth
                        resource={resource}
                    />
                    <TextInput source="channel" validate={required()} fullWidth resource={resource} />
                </UpdateDialogForm>
            )}
        </>
    );
};

RowActions.defaultProps = {
    addLabel: false,
    sortable: false,
};

export default ExternalSalesTab;

import AlarmIcon from '@material-ui/icons/Alarm';

import ProcessingTimeList from './ProcessingTimeList';
import ProcessingTimeEdit from './ProcessingTimeEdit';

export default {
    icon: AlarmIcon,
    list: ProcessingTimeList,
    edit: ProcessingTimeEdit,
    show: null,
    create: null,
};

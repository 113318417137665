import BarcodeScannerReferenceInput from '@components/input/BarcodeScannerReferenceInput';

import { Reclamation } from '@js/interfaces/reclamation';
import { useCreate } from 'react-admin';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useResourceFieldName from '@js/hooks/useResourceFieldName';

type Props = {
    source: string;
    record?: Reclamation;
    resource?: string;
};

const LocationInput = (props: Props) => {
    const reference = 'locations';
    const [create] = useCreate(reference);
    const fieldName = useResourceFieldName({ resource: reference });
    const notifyFailure = useNotifyHttpError();

    return (
        <BarcodeScannerReferenceInput
            {...props}
            onCreate={async (value) => {
                if (!value) return;
                const { data: createdRecord } = await create(
                    {
                        payload: {
                            data: {
                                [fieldName]: value,
                            },
                        },
                    },
                    {
                        onFailure: notifyFailure,
                        returnPromise: true,
                    },
                );
                return createdRecord;
            }}
        />
    );
};

export default LocationInput;

import { useState } from 'react';
import { SplitType } from '@js/interfaces/product';

const STORE_KEY = 'app/resources/products/view';

const useListView = () => {
    const [view, setView] = useState<SplitType>(() => {
        const storedView = window.localStorage.getItem(STORE_KEY);

        if (storedView) return storedView as SplitType;
        return 'simple';
    });

    return [
        view,
        (view: SplitType) => {
            window.localStorage.setItem(STORE_KEY, view);
            setView(view);
        },
    ] as const;
};

export default useListView;

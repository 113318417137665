/* eslint-disable react/jsx-key */
import { useMemo } from 'react';
import { useGetResourceLabel } from 'react-admin';

import DateRangeFilter from '@components/filter/DateRangeFilter';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';

const useFilters = () => {
    const getResourceLabel = useGetResourceLabel();

    return useMemo(
        () => [
            <DateRangeFilter source="createdAt" alwaysOn />,
            <AutoCompleteGuesserInput label={getResourceLabel('users')} source="createdBy" alwaysOn />,
            <AutoCompleteGuesserInput label={getResourceLabel('stores')} reference="stores" source="store" alwaysOn />,
        ],
        [getResourceLabel],
    );
};

export default useFilters;

import { loadViteEnv } from '@js/utility/envUtil';

export interface IcecatProduct {
    GeneralInfo?: {
        IcecatId: number;
        ReleaseDate: string;
        EndOfLifeDate: string;
        Title: string;
        Brand: string;
        BrandLogo: string;
        BrandInfo: {
            BrandName: string;
            BrandLocalName: string;
            BrandLogo: string;
        };
        ProductName: string;
        BrandPartCode: string;
        GTIN: [string];
        Category: { CategoryID: number; Name: { Value: string; Language: string } };
        ProductFamily: { ProductFamilyID: number; Value: string; Language: string };
        ProductSeries: { SeriesID: number; Value: string; Language: string };
        Description: {
            ID: number;
            LongDesc: string;
            LongProductName: string;
            MiddleDesc: string;
            Disclaimer: string;
            ManualPDFURL: string;
            ManualPDFSize: number;
            LeafletPDFURL: string;
            PDFSize: number;
            URL: string;
            WarrantyInfo: string;
            Updated: string;
        };
        SummaryDescription: {
            ShortSummaryDescription: string;
            LongSummaryDescription: string;
        };
        BulletPoints: [];
    };
    Image?: {
        HighPic: string;
        HighPicSize: number;
        HighPicHeight: number;
        HighPicWidth: number;
        LowPic: string;
        LowPicSize: number;
        LowPicHeight: number;
        LowPicWidth: number;
        Pic500x500: string;
        Pic500x500Size: number;
        Pic500x500Height: number;
        Pic500x500Width: number;
        ThumbPic: string;
        ThumbPicSize: number;
    };
    Multimedia?: {
        ID: string;
        URL: string;
        Type: string;
        ContentType: string;
        KeepAsUrl: number;
        Description: string;
        Size: number;
        IsPrivate: number;
        Updated: string;
        Language: string;
        IsVideo: number;
        PreviewUrl?: string;
    }[];
    Gallery?: {
        ID: string;
        LowPic: string;
        LowSize: number;
        LowHeight: number;
        LowWidth: number;
        ThumbPic: string;
        ThumbPicSize: number;
        Pic: string;
        Size: number;
        PicHeight: number;
        PicWidth: number;
        Pic500x500: string;
        Pic500x500Size: number;
        Pic500x500Height: number;
        Pic500x500Width: number;
        No: number;
        IsMain: string;
        Updated: string;
        IsPrivate: number;
        Type: string;
        Attributes: { OriginalFileName: string };
    }[];
    FeatureLogos?: {
        LogoPic: string;
        Width: number;
        Height: number;
        Size: number;
        ThumbPic: string;
        FeatureID: number;
        Value: string;
        Description: {
            Value: string;
            Language: string;
        };
        KeyLogo: number;
    }[];
    FeaturesGroups?: {
        ID: number;
        SortNo: string;
        FeatureGroup: { ID: string; Name: { Value: string; Language: string } };
        Features: {
            Localized: number;
            ID: number;
            Type: string;
            Value: string;
            CategoryFeatureId: string;
            CategoryFeatureGroupID: string;
            SortNo: string;
            PresentationValue: string;
            RawValue: string;
            LocalValue: [];
            Description: string;
            Mandatory: string;
            Searchable: string;
            Feature: {
                ID: string;
                Sign: string;
                Measure: {
                    ID: string;
                    Sign: string;
                    Signs: {
                        ID: string;
                        _: string;
                        Language: string;
                    };
                };
                Name: { Value: string; Language: string };
            };
        }[];
    }[];
}

type GetProductOptions =
    | {
          Brand: string;
          ProductCode: string;
      }
    | {
          GTIN: string;
      }
    | {
          icecat_id: string;
      };

type Params = {
    username: string;
    lang: string;
};

class OpenIcecat {
    constructor(private baseUrl: string, private baseParams: Params) {}

    public async getProduct(options: GetProductOptions, init?: RequestInit): Promise<IcecatProduct> {
        const response = await fetch(
            `${this.baseUrl}?` + new URLSearchParams({ ...this.baseParams, ...options }),
            init,
        );
        return this.parse(response);
    }

    private async parse(response: any): Promise<any> {
        const res = await response.json();
        if (res?.data && res?.msg === 'OK') {
            return res.data;
        }

        if (res.statusCode && res.statusCode === 8) {
            throw new Error(`cannot access 'full icecat' products`);
        }
        if (res.Error && res.Message) {
            throw new Error(`${res.Error}: ${res.Message}`);
        }

        return {};
    }
}

const { VITE_ICE_CAT_API_URL, VITE_ICE_CAT_USER_NAME, VITE_ICE_CAT_LOCALE } = loadViteEnv();

export const iceCatClient = new OpenIcecat(VITE_ICE_CAT_API_URL, {
    username: VITE_ICE_CAT_USER_NAME,
    lang: VITE_ICE_CAT_LOCALE,
});

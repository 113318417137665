import { createUrl, GetParams } from '@js/request/apiRequest';

export const downloadFileBlob = (blob: Blob, filename: string) => {
    const fakeLink = document.createElement('a');

    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', filename);
        fakeLink.click();
    }

    fakeLink.remove();
};

export const downloadFileUrl = async (path: string, params?: GetParams) => {
    const url = createUrl(path, params);

    const link = document.createElement('a');
    link.href = url.toString();
    link.target = '_blank';
    link.download = '';
    link.hidden = true;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const parseFilenameFromContentDisposition = (contentDisposition: string): string => {
    const parts = contentDisposition.split(';');

    for (const part of parts) {
        if (part.includes('filename')) {
            const [, possibleFilename] = part.trim().split('=');

            if (possibleFilename) {
                return possibleFilename;
            }
        }
    }

    return '[undefined]';
};

import { Edit, EditProps, SimpleForm } from 'react-admin';

import InputGuesser from '@components/form/InputGuesser';
import TimeInput from './input/TimeInput';

const ProcessingTimeEdit = (props: EditProps) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TimeInput source="time" transformIntoSeconds />
                <InputGuesser source="description" />
            </SimpleForm>
        </Edit>
    );
};

export default ProcessingTimeEdit;

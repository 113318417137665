import { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { Button as MuiButton, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import CancelIcon from '@material-ui/icons/Cancel';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { useCheckForApplicationUpdate } from '@js/hooks/useCheckForApplicationUpdate';

const CheckForApplicationUpdate = () => {
    const [openDialog, setOpenDialog] = useState(true);
    const translate = useTranslate();
    const applicationUpdateAvailable = useCheckForApplicationUpdate();
    const classes = useStyles();

    if (!applicationUpdateAvailable) {
        return null;
    }

    const handleConfirm = () => {
        window.location.reload();
    };

    return (
        <>
            <Alert
                className={classes.alert}
                severity="info"
                action={
                    <MuiButton color="inherit" size="small" onClick={() => setOpenDialog(true)}>
                        {translate('app.action.update_application')}
                    </MuiButton>
                }
            >
                {translate('app.label.application_update_available')}
            </Alert>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} onConfirmReload={handleConfirm} />
        </>
    );
};

const Dialog = ({
    open,
    onClose,
    onConfirmReload,
}: {
    open: boolean;
    onClose: () => void;
    onConfirmReload: () => void;
}) => {
    const translate = useTranslate();

    return (
        <MuiDialog open={open}>
            <DialogTitle>{translate('app.action.update_application')}</DialogTitle>
            <DialogContent>{translate('app.message.page_reload_confirm')}</DialogContent>
            <DialogActions>
                <Button label="ra.action.cancel" color="default" size="small" onClick={onClose}>
                    <CancelIcon />
                </Button>
                <Button
                    label="ra.action.confirm"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={onConfirmReload}
                >
                    <ErrorOutlineIcon />
                </Button>
            </DialogActions>
        </MuiDialog>
    );
};

const useStyles = makeStyles({
    alert: {
        padding: '0 8px',
    },
});

export default CheckForApplicationUpdate;

import { FC, ReactElement, useState } from 'react';
import { Button, Confirm, useListContext, useResourceContext } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';

import { useEntrypoint } from '@js/context/AppConfigContext';
import { createListURL } from '@js/utility/hydraRequestUtility';
import { downloadFileUrl } from '@js/utility/downloadFileUtil';

interface ExportButtonProps {
    resource?: string;
    icon?: ReactElement;
    label?: string;
    exportUrl?: string;
    warnAfterNumberOfRecords?: number;
}

const ExportButton: FC<ExportButtonProps> = ({
    label = 'ra.action.export',
    icon = <DownloadIcon />,
    exportUrl = 'export',
    warnAfterNumberOfRecords = 200,
    ...rest
}) => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const entrypoint = useEntrypoint();
    const { total, selectedIds, loading: listLoading, currentSort, filterValues } = useListContext();
    const resource = useResourceContext(rest);
    const shouldWarn = selectedIds.length === 0 && total > warnAfterNumberOfRecords;

    const handleExportClick = () => {
        if (shouldWarn) {
            setOpenConfirm(true);
            return;
        }

        download();
    };

    const download = () => {
        const url = createListURL(new URL(`${entrypoint}/${resource}/${exportUrl}`), {
            sort: currentSort,
            filter: { ...filterValues, ...(selectedIds.length && { id: selectedIds }) },
        });

        downloadFileUrl(url.toString());
    };

    return (
        <>
            <Button onClick={handleExportClick} label={label} disabled={total === 0 || listLoading}>
                {icon}
            </Button>
            <Confirm
                isOpen={openConfirm}
                title="ra.action.confirm"
                content="app.message.bulk_export_warning"
                translateOptions={{ total }}
                onConfirm={() => {
                    download();
                    setOpenConfirm(false);
                }}
                onClose={() => setOpenConfirm(false)}
            />
        </>
    );
};

export default ExportButton;

import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { FC, ReactNode } from 'react';
import { Field } from '@api-platform/api-doc-parser';
import { ReferenceArrayField, SingleFieldList, useTranslate } from 'react-admin';
import InfoIcon from '@material-ui/icons/Info';

import FieldGuesser from '@components/field/FieldGuesser';
import FileField from '@components/field/FileField';
import CopyableTextField from '@components/field/CopyableTextField';
import ArrayChipField from '@components/field/ArrayChipField';
import NullableBooleanField from '@components/field/NullableBooleanField';
import StatusesHistory from '@components/field/StatusesHistory';

import ReclamationProcessingTimeField from '../field/ReclamationProcessingTimeField';
import PriceReclamationCurrencyField from '../field/PriceReclamationCurrencyField';
import StatusPreviewField from '../field/StatusPreviewField';
import ResellersField from '../field/ResellersField';
import LinksField from '../field/LinksField';
import TotalSpendCostField from '../field/TotalSpendCostField';
import QuantityField from '../../reclamation/field/QuantityField';
import ComposedIdentifierField from '../../reclamation/field/ComposedIdentifierField';
import CountReturnsField from '../../reclamation/field/CountReturnsField';

import useIsCopiedFieldFromSavingsCalculation from '../../reclamation/input/useIsCopiedFieldFromSavingsCalculation';
import { ComponentChoices, Reclamation } from '@js/interfaces/reclamation';

export interface ReclamationFieldGuesserProps {
    field: Field;
    source: string;
    addLabel?: boolean;
    sortable?: boolean;
    record?: Reclamation;
    resource?: string;
}

const ReclamationFieldGuesser: FC<ReclamationFieldGuesserProps> = ({ field, ...props }) => {
    const isFieldValueCopiedFromCo2 = useIsCopiedFieldFromSavingsCalculation(props);

    switch (field.name) {
        case 'powerAdapter':
            return <NullableBooleanField {...props} />;
        case 'processingTimes':
            return <ReclamationProcessingTimeField {...props} />;
        case 'status':
            return (
                <StatusPreviewField
                    {...props}
                    history={
                        <StatusesHistory record={props.record} target="reclamation" reference="reclamation_statuses" />
                    }
                />
            );
        case 'cost':
        case 'costRedeemedItem':
        case 'recyclingFee':
        case 'purchasePrice':
        case 'insurancePrice':
        case 'costSparePart':
        case 'outgoingPrice':
            return (
                <TooltipWrapper valueCopiedFromCo2={isFieldValueCopiedFromCo2}>
                    <PriceReclamationCurrencyField {...props} />
                </TooltipWrapper>
            );
        case 'resellers':
            return <ResellersField {...props} />;
        case 'composedIdentifier':
            return <ComposedIdentifierField {...props} />;
        case 'label':
            return <CopyableTextField {...props} />;
        case 'images':
            return (
                <ReferenceArrayField reference="reclamation_media_objects" {...props}>
                    <SingleFieldList linkType={false}>
                        <FileField />
                    </SingleFieldList>
                </ReferenceArrayField>
            );
        case 'links':
            return <LinksField {...props} />;
        case 'totalSpendCost':
            return <TotalSpendCostField {...props} />;
        case 'containsComponents':
            return <ArrayChipField {...props} choices={ComponentChoices} />;
        case 'quantity':
            return <QuantityField {...props} />;
        case 'countReturns':
            return <CountReturnsField {...props} />;
        default:
            return (
                <TooltipWrapper valueCopiedFromCo2={isFieldValueCopiedFromCo2}>
                    <FieldGuesser field={field} {...props} />
                </TooltipWrapper>
            );
    }
};

const TooltipWrapper = ({ children, valueCopiedFromCo2 }: { children: ReactNode; valueCopiedFromCo2: boolean }) => {
    const classes = useTooltipWrapperStyle();
    const translate = useTranslate();

    if (!valueCopiedFromCo2) return <>{children}</>;

    return (
        <Box display="flex">
            {children}
            <Tooltip title={translate('app.form.helperText.copied_field_from_savings_calculation')}>
                <InfoIcon className={classes.icon} />
            </Tooltip>
        </Box>
    );
};

const useTooltipWrapperStyle = makeStyles((theme) => ({
    icon: {
        color: theme.palette.warning.dark,
        fontSize: '1rem',
        marginLeft: theme.spacing(0.5),
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

ReclamationFieldGuesser.defaultProps = {
    addLabel: true,
};

export default ReclamationFieldGuesser;

import { FC, useCallback, useEffect, useRef } from 'react';
import {
    Datagrid,
    DateField,
    LinearProgress,
    Link,
    linkToRecord,
    List,
    ListProps,
    Record as RaRecord,
    TextField,
    useGetOne,
    useRecordContext,
    useRefresh,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FunctionField from '@components/field/FunctionField';
import ReferenceField from '@components/field/ReferenceField';
import DeleteIconButton from '@components/button/DeleteIconButton';

import StarredField from './field/StarredField';
import TypeField from './field/TypeField';
import Aside from './Aside';
import BulkActionButtons from './BulkActionButtons';
import ReferenceInfo from './ReferenceInfo';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useOnNewNotificationSubscription from './useOnNewNotificationSubscription';

import { UserNotification } from '@js/interfaces/usernotification';
import { Errand } from '@js/interfaces/errand';
import { Iri } from '@js/interfaces/ApiRecord';
import { Store } from '@js/interfaces/store';
import { User } from '@js/interfaces/user';

const UserNotificationList: FC<ListProps> = (props) => {
    const classes = useStyles();
    const refresh = useRefresh();

    const onNewNotification = useCallback(
        (notification: UserNotification) => {
            if (!notification.readAt) {
                refresh();
            }
        },
        [refresh],
    );

    useOnNewNotificationSubscription(onNewNotification);

    return (
        <List
            {...props}
            sort={{ field: 'createdAt', order: 'desc' }}
            aside={<Aside />}
            exporter={false}
            classes={{ main: classes.list }}
            bulkActionButtons={<BulkActionButtons />}
        >
            <Datagrid
                expand={<NotificationExpand />}
                rowStyle={rowStyle}
                className={classes.datagrid}
                rowClick="expand"
            >
                <DateField source="createdAt" showTime />
                <StarredField />
                <ReferenceField reference="users" source="from">
                    <TextField source="fullName" />
                </ReferenceField>
                <TypeField />
                <FunctionField<UserNotification>
                    source="message"
                    render={(record) => (
                        <Box textOverflow="ellipsis" overflow="hidden">
                            <Typography component="span" variant="body2" noWrap>
                                {record?.message}
                            </Typography>
                        </Box>
                    )}
                />
                <Box display="flex" justifyContent="end">
                    <DeleteIconButton />
                </Box>
            </Datagrid>
        </List>
    );
};

const NotificationExpand = (props: { record?: UserNotification }) => {
    const record = useRecordContext(props);
    const { id, reference, readAt } = record;

    const refresh = useRefresh();
    const [updateAsRead] = useUpdate('user_notifications', id, { readAt: new Date().toISOString() }, record, {
        mutationMode: 'pessimistic',
        onSuccess: () => {
            refresh();
        },
    });
    const markAsReadRef = useRef(false);

    useEffect(() => {
        if (!id || markAsReadRef.current) return;

        if (null === readAt) {
            markAsReadRef.current = true;
            updateAsRead();
        }
    }, [id, readAt, updateAsRead]);

    return (
        <Box my={2}>
            <table>
                <tbody>
                    {record.relatedErrand ? (
                        <RelatedErrandInfo errandId={record.relatedErrand} />
                    ) : (
                        <ReferenceInfo reference={reference} />
                    )}
                </tbody>
            </table>
        </Box>
    );
};

const RelatedErrandInfo = ({ errandId }: { errandId: Iri }) => {
    const { data: errand, loaded: errandLoaded } = useGetOne<Errand>('errands', errandId);
    const { data: store } = useGetOne<Store>('stores', errand?.store ?? 0, {
        enabled: !!errand?.store,
    });
    const { data: assignedUser } = useGetOne<User>('users', errand?.assignedUser ?? 0, {
        enabled: !!errand?.assignedUser,
    });
    const getFieldLabel = useTranslateResourceField('errands');
    const translate = useTranslate();

    if (!errandLoaded || !errand) {
        return <LinearProgress />;
    }

    return (
        <>
            <tr>
                <td>
                    <b>{getFieldLabel('name')}:</b>
                </td>
                <td>{errand.name ? `${errand.name} [${errand.composedIdentifier}]` : errand.composedIdentifier}</td>
            </tr>
            <tr>
                <td>
                    <b>{getFieldLabel('assignedUser')}:</b>
                </td>
                <td>{assignedUser ? assignedUser.fullName : '---'}</td>
            </tr>
            <tr>
                <td>
                    <b>{getFieldLabel('store')}:</b>
                </td>
                <td>{store ? store.name : '---'}</td>
            </tr>
            <tr>
                <td>
                    <Link to={linkToRecord('/errands', errand.id, 'show')}>{translate('ra.action.show')}</Link>
                </td>
            </tr>
        </>
    );
};

const rowStyle = (record?: RaRecord) => {
    const notification = record as UserNotification | undefined;
    return {
        ...(notification?.readAt === null && { backgroundColor: '#efe' }),
    };
};

const useStyles = makeStyles({
    list: {
        flexDirection: 'row-reverse',
    },
    // Is there better to style columns width? Here it depends on source name of fields
    datagrid: {
        '& .MuiTableCell-paddingCheckbox': {
            paddingLeft: 0,
        },
        '& .column-createdAt': {
            width: '150px',
        },
        '& .column-starred': {
            width: '24px',
        },
    },
});

export default UserNotificationList;

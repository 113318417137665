import { FC } from 'react';
import { Create, CreateProps } from 'react-admin';

import ProductForm from './ProductForm';

const ProductCreate: FC<CreateProps> = (props) => {
    return (
        <Create {...props}>
            <ProductForm />
        </Create>
    );
};

export default ProductCreate;

import { FC, lazy, Suspense, useState } from 'react';
import { LinearProgress, TextInput, TextInputProps, useTranslate } from 'react-admin';
import { IconButton, InputAdornment } from '@material-ui/core';
import { useField } from 'react-final-form';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';

import DialogCodeScanner from '@components/camera/DialogCodeScanner';
import useInputAutoValidator from '@js/hooks/useInputAutoValidator';

const BarcodeScanner = lazy(() => import('@components/camera/BarcodeScanner'));

const BarcodeScannerInput: FC<TextInputProps & { inputRef?: (ref: HTMLInputElement) => void }> = ({
    inputRef,
    ...props
}) => {
    const [open, setOpen] = useState(false);
    const { input } = useField(props.source);
    const validate = useInputAutoValidator(props);
    const translate = useTranslate();

    const handleSuccess = (result: string) => {
        input.onChange(result);
        setOpen(false);
    };

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton disableRipple onClick={() => setOpen(true)}>
                <HorizontalSplitIcon />
            </IconButton>
        </InputAdornment>
    );

    return (
        <>
            <TextInput {...props} InputProps={{ endAdornment, inputRef }} validate={validate} />
            <DialogCodeScanner
                onClose={() => setOpen(false)}
                open={open}
                title={translate('app.filter.fields.scanBarcode.label')}
                loading={false}
            >
                <Suspense fallback={<LinearProgress />}>
                    <BarcodeScanner onSuccess={handleSuccess} />
                </Suspense>
            </DialogCodeScanner>
        </>
    );
};

export default BarcodeScannerInput;

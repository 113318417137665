import { FC } from 'react';
import { TextInput, useResourceContext, useTranslate } from 'react-admin';
import { Box, Grid, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';
import VideoImageInput from '@components/input/VideoImageInput';
import FileInputPreview from '@components/input/FileInputPreview';
import PriceInput from '@components/input/PriceInput';
import { StatusInput } from '@components/resource/errand/input/StatusInput';

import BundleItemInput from './input/BundleItemInput';
import PhysicalStoreSaleInput from './input/PhysicalStoreSaleInput';
import SyncToSelloInput from './input/SyncToSelloInput';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { Product } from '@js/interfaces/product';

type Props = { resource?: string; record?: Product };

const BundleForm: FC<Props> = (props) => {
    const resource = useResourceContext(props);
    const getFieldLabel = useTranslateResourceField('reclamations');
    const translate = useTranslate();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Alert severity="info">{translate('resources.products.message.create_bundle_tip')}</Alert>
                <SyncToSelloInput />
            </Grid>
            <Grid item xs={12}>
                <Box mt={1} display="flex" gridGap="20px">
                    <TextField
                        size="small"
                        label={getFieldLabel('quantity')}
                        value={1}
                        margin="dense"
                        variant="filled"
                        disabled
                        helperText={translate('resources.products.message.bundle_quantity_tip')}
                    />
                    <PhysicalStoreSaleInput />
                    <StatusInput source="status" />
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextInput label={getFieldLabel('title')} source="title" resource={resource} fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
                <PriceInput
                    label={getFieldLabel('insurancePrice')}
                    source="insurancePrice"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <PriceInput
                    label={getFieldLabel('outgoingPrice')}
                    source="outgoingPrice"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <AutoCompleteGuesserInput
                    label={getFieldLabel('manufacturer')}
                    source="manufacturer"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <AutoCompleteGuesserInput label={getFieldLabel('model')} source="model" resource={resource} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
                <AutoCompleteGuesserInput label={getFieldLabel('grade')} source="grade" resource={resource} fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
                <AutoCompleteGuesserInput
                    label={getFieldLabel('location')}
                    source="location"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <BundleItemInput fullWidth />
                <TextInput
                    multiline
                    label={getFieldLabel('classificationOutComment')}
                    source="classificationOutComment"
                    resource={resource}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <VideoImageInput source="images" multiple accept="image/*, application/pdf, video/*">
                    <FileInputPreview reference="reclamation_media_objects" />
                </VideoImageInput>
            </Grid>
        </Grid>
    );
};

export default BundleForm;

import { Identifier } from 'react-admin';
import { useEffect } from 'react';
import { doFetch, post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

/**
 * Lock and unlock record by ID
 */
const useLockRecord = (id: Identifier | undefined) => {
    const notifyError = useNotifyHttpError();

    useEffect(() => {
        if (!id) return;

        const url = '/api/lock_records';
        const body = JSON.stringify({ iri: id });

        post(url, {
            body,
        }).catch((error) => {
            notifyError(error);
        });

        return () => {
            doFetch(url, {
                method: 'DELETE',
                body,
            }).catch((error) => {
                notifyError(error);
            });
        };
    }, [id, notifyError]);
};

export default useLockRecord;

import { InjectedFieldProps, useRecordContext } from 'react-admin';
import { Product } from '@js/interfaces/product';
import { Box } from '@material-ui/core';

import PrintItemLabelButton from '@components/button/PrintItemLabelButton';
import LockRecordIcon from '@components/detail/LockRecordIcon';
import SyncSelloButton from '@components/button/SyncSelloButton';
import DeleteIconButton from '@components/button/DeleteIconButton';
import ShowIconButton from '@components/button/ShowIconButton';
import EditIconButton from '@components/button/EditIconButton';

type Props = InjectedFieldProps<Product>;

const RowActions = (props: Props) => {
    const record = useRecordContext(props);
    if (!record) return null;

    return (
        <Box display="flex" justifyContent="end">
            <LockRecordIcon />
            <SyncSelloButton {...props} />
            <PrintItemLabelButton {...props} />
            <ShowIconButton {...props} />
            <EditIconButton {...props} />
            <DeleteIconButton
                confirmContent={record.syncedWithSello ? 'resources.products.message.was_synced_with_sello' : undefined}
                disabledReason={record.deleteDisableReason}
            />
        </Box>
    );
};

export default RowActions;

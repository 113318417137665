import { BooleanInput, useRecordContext } from 'react-admin';
import { FC } from 'react';
import { useField } from 'react-final-form';

import { Product } from '@js/interfaces/product';

type Props = {
    source?: string;
    resource?: string;
    record?: Product;
};

const SyncToSelloInput: FC<Props> = ({ source, ...props }) => {
    const {
        input: { value: physicalStoreSale },
    } = useField<boolean | undefined>('physicalStoreSale', { subscription: { value: true } });
    const record = useRecordContext(props);

    const isSellOnPhysicalStore = Boolean(physicalStoreSale || record?.physicalStoreSale);
    const helperText = isSellOnPhysicalStore
        ? 'resources.products.helper_text.disable_sync_sello_sell_on_store'
        : undefined;

    return <BooleanInput source={source!} {...props} helperText={helperText} disabled={isSellOnPhysicalStore} />;
};

SyncToSelloInput.defaultProps = {
    source: 'syncToSello',
    resource: 'products',
};

export default SyncToSelloInput;

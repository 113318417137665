import { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Button, LinearProgress, useNotify, useRecordContext, useTranslate } from 'react-admin';
import Queue from '@material-ui/icons/Queue';

import LoadingButton from '@components/button/LoadingButton';
import IconButton from '@components/button/IconButton';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useFetchGet from '@js/hooks/useFetchGet';
import { post } from '@js/request/apiRequest';
import { useIsFeatureDisabled } from '@js/context/AppConfigContext';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { Errand } from '@js/interfaces/errand';

type Props = { record?: Errand; resource?: string };

const IceCatBatchIconButtonView = (props: Props) => {
    const [open, setOpen] = useState(false);
    const isSmallScreen = useIsSmallScreen();
    const translate = useTranslate();
    const record = useRecordContext(props);

    if (!record) return null;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <IconButton label="app.action.icecat" onClick={handleOpen}>
                <InfoIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" fullScreen={isSmallScreen}>
                <DialogTitle>{translate('app.action.icecat')}</DialogTitle>
                <IceCatDialogContent errand={record} onClose={handleClose} />
            </Dialog>
        </>
    );
};

type IcecatInfoResponse = {
    validEanCount: number;
    invalidEanCount: number;
    foundInfoCount: number;
    icecatErrorCount: number;
    imagesCount: number;
    videosCount: number;
};

const IceCatDialogContent = ({ errand, onClose }: { errand: Errand; onClose: () => void }) => {
    const { data: info, loading: loadingInfo } = useFetchGet<IcecatInfoResponse>(`${errand.id}/icecat/info`);
    const [copyInProgress, setCopyInProgress] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();

    const handleCopy = async () => {
        setCopyInProgress(true);

        try {
            await post(`${errand.id}/icecat/copy`);
            notify('app.message.icecat_copied');
            onClose();
        } catch (error) {
            notifyFailure(error);
        } finally {
            setCopyInProgress(false);
        }
    };

    return (
        <>
            <DialogContent dividers>
                {loadingInfo ? (
                    <LinearProgress />
                ) : (
                    info && (
                        <>
                            <Typography>
                                <span>
                                    {`${translate('app.label.eans_found')}: ${info.foundInfoCount} ${translate(
                                        'app.label.of',
                                    )} (${info.validEanCount})`}
                                </span>
                                <br />
                                <span>
                                    {translate('app.label.eans_not_found')}: {info.icecatErrorCount}
                                </span>
                            </Typography>
                            <Box my={1}>
                                <Divider />
                            </Box>
                            <Typography>
                                {translate('app.label.images_found')}: {info.imagesCount}
                            </Typography>
                            <Box my={1}>
                                <Divider />
                            </Box>
                            <Typography>
                                {translate('app.label.videos_found')}: {info.videosCount}
                            </Typography>
                        </>
                    )
                )}
            </DialogContent>
            <DialogActions>
                <Button label="ra.action.close" onClick={onClose} />
                <LoadingButton
                    label="app.action.copy"
                    loading={copyInProgress}
                    icon={<Queue />}
                    onClick={handleCopy}
                    disabled={loadingInfo || copyInProgress || !info?.foundInfoCount}
                ></LoadingButton>
            </DialogActions>
        </>
    );
};

export default function IceCatBatchIconButton(props: Props) {
    const isFeatureDisabled = useIsFeatureDisabled();

    if (isFeatureDisabled('Icecat')) return null;

    return <IceCatBatchIconButtonView {...props} />;
}

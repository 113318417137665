import {
    forwardRef,
    ForwardRefRenderFunction,
    Fragment,
    ReactNode,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import {
    RecordContextProvider,
    ReferenceArrayInput,
    ReferenceInput as RaReferenceInput,
    required,
    SelectInput,
    useGetResourceLabel,
    useLocale,
    useTranslate,
    Validator,
} from 'react-admin';
import { Form, useField, useForm } from 'react-final-form';
import { Field, Resource } from '@api-platform/api-doc-parser';
import CopyIcon from '@material-ui/icons/FileCopy';

import IconButton from '@components/button/IconButton';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';
import { cloneReclamation } from '@js/utility/cloneUtil';
import { useGetFieldName } from '@js/hooks/useResourceFieldName';
import useGetResourceField from '@js/hooks/useGetResourceField';
import { getFieldType } from '@js/utility/fieldsUtil';
import { useFormatCentsPrice } from '@js/hooks/useFormatPrice';
import useFetchGet from '@js/hooks/useFetchGet';

import { Reclamation } from '@js/interfaces/reclamation';
import { Iri } from '@js/interfaces/ApiRecord';

export type SuggestCloneIconButtonHandle = {
    open: () => void;
    close: () => void;
};

type Props = {
    suggestSource: string;
};

type Suggestion = {
    [key in keyof Reclamation]: Array<string | number>;
};

interface ReferenceField extends Field {
    reference: Resource;
}

const SuggestCloneIconButton: ForwardRefRenderFunction<SuggestCloneIconButtonHandle, Props> = (
    { suggestSource },
    forwardedRef,
) => {
    const [open, setOpen] = useState(false);
    const { loading, suggestions } = useSuggestions(suggestSource);
    const isSmallScreen = useIsSmallScreen();
    const handleClone = useSuggestCloneHandler();

    const handleClose = () => setOpen(false);
    const handleOpen = () => {
        if (suggestions.length === 0 || loading) return;
        setOpen(true);
    };

    useImperativeHandle(forwardedRef, () => ({
        open: handleOpen,
        close: handleClose,
    }));

    const handleSelect = (suggestion: Partial<Reclamation>) => {
        handleClone(suggestion);
        handleClose();
    };

    return (
        <>
            <IconButton
                label="app.action.copy"
                onClick={handleOpen}
                loading={loading}
                disabledReason={suggestions.length === 0 && 'ra.page.not_found'}
                size="medium"
                tooltipPlacement="top-start"
            >
                <CopyIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" fullScreen={isSmallScreen}>
                <SuggestionDialog onClose={handleClose} suggestions={suggestions} onSelect={handleSelect} />
            </Dialog>
        </>
    );
};

const SuggestionDialog = ({
    onClose,
    onSelect,
    suggestions,
}: {
    onClose: () => void;
    suggestions: Suggestion[];
    onSelect: (suggestion: Partial<Reclamation>) => void;
}) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion | null>(null);
    const getField = useGetResourceField({ resource: 'reclamations' });
    const { schemaAnalyzer } = useHydraSchemaContext();

    const isMultipleValueField = (source: string) => {
        if (source.startsWith('@') || source === 'id') return false;

        const field = getField(source);

        if (field.reference !== null && typeof field.reference === 'object' && field.maxCardinality !== 1) {
            return true;
        }

        return getFieldType(field, schemaAnalyzer) === 'array';
    };

    const shouldSelectOneValueForField = ([source, value]: [string, Array<string | number>]) => {
        if (value.length < 2) return false;

        return !isMultipleValueField(source);
    };

    const suggestionToReclamation = (suggestion: Suggestion) => {
        return Object.entries(suggestion).reduce((acc, [field, values]) => {
            if (!Array.isArray(values)) throw new Error('Invalid suggestion format');

            const value = isMultipleValueField(field) ? values : values[0];

            return { ...acc, [field]: value };
        }, {} as Partial<Reclamation>);
    };

    const handleSuggestionSelect = (suggestion: Suggestion) => {
        const hasMultipleValueFields = Object.entries(suggestion).some(shouldSelectOneValueForField);
        // If there are multiple values in a field of suggestion, show forms that allow user to choose the correct value
        if (hasMultipleValueFields) {
            setSelectedSuggestion(suggestion);
        } else {
            onSelect(suggestionToReclamation(suggestion));
        }
    };

    const handleMultipleValueFormSubmit = (suggestion: Suggestion) => (data: Partial<Reclamation>) => {
        onSelect({ ...suggestionToReclamation(suggestion), ...data });
    };

    const closeButton = <Button onClick={onClose}>{translate('ra.action.cancel')}</Button>;
    const title = getResourceLabel('reclamations');

    return selectedSuggestion ? (
        <SuggestionMultipleValueForm
            multipleValueFields={Object.entries(selectedSuggestion).filter(shouldSelectOneValueForField)}
            closeButton={closeButton}
            onSubmit={handleMultipleValueFormSubmit(selectedSuggestion)}
            title={title}
        />
    ) : (
        <SuggestionList
            suggestions={suggestions}
            onSelect={handleSuggestionSelect}
            closeButton={closeButton}
            title={title}
        />
    );
};

const SuggestionList = ({
    suggestions,
    onSelect,
    closeButton,
    title,
}: {
    suggestions: Suggestion[];
    onSelect: (suggestion: Suggestion) => void;
    closeButton: ReactNode;
    title: string;
}) => {
    const getFieldLabel = useTranslateResourceField('reclamations');
    const translate = useTranslate();
    const eanLabel = getFieldLabel('ean');
    const lastIndex = suggestions.length - 1;

    return (
        <>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <List component="nav">
                    {suggestions.map((suggestion, index) => (
                        <Fragment key={suggestion.id.join('')}>
                            <ListItem
                                alignItems="flex-start"
                                onClick={() => onSelect(suggestion)}
                                button
                                disableGutters
                            >
                                <ListItemText
                                    primary={suggestion.title?.join(' | ') || ''}
                                    secondary={`${eanLabel}: ${
                                        suggestion.ean?.[0] || `[${translate('app.label.not_defined')}]`
                                    } `}
                                />
                            </ListItem>
                            {lastIndex !== index && <Divider />}
                        </Fragment>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>{closeButton}</DialogActions>
        </>
    );
};

const SuggestionMultipleValueForm = ({
    multipleValueFields,
    onSubmit,
    closeButton,
    title,
}: {
    multipleValueFields: Array<[string, Array<string | number>]>;
    closeButton: ReactNode;
    onSubmit: (data: Partial<Reclamation>) => void;
    title: string;
}) => {
    const resource = 'reclamations';
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField(resource);
    const getField = useGetResourceField({ resource });
    const formatCentsPrice = useFormatCentsPrice();
    const { schemaAnalyzer } = useHydraSchemaContext();

    return (
        <Form onSubmit={onSubmit}>
            {(formProps) => {
                return (
                    <RecordContextProvider value={{}}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            {multipleValueFields.map(([source, values]) => {
                                const field = getField(source);
                                // Temporary solution to make soldGrade field not required
                                const validate = source !== 'soldGrade' ? required() : undefined;

                                if (field.reference !== null && typeof field.reference === 'object') {
                                    return (
                                        <ReferenceInput
                                            key={source}
                                            source={source}
                                            field={field as ReferenceField}
                                            ids={values as string[]}
                                            validate={validate}
                                        />
                                    );
                                }

                                const formatChoiceText = (value: string | number) => {
                                    const type = getFieldType(field, schemaAnalyzer);

                                    switch (type) {
                                        case 'price':
                                            return formatCentsPrice(value as number);
                                        case 'dateTime':
                                            return value ? new Date(value as string).toLocaleString() : '';
                                        default:
                                            return value;
                                    }
                                };

                                return (
                                    <SelectInput
                                        key={source}
                                        source={source}
                                        label={getFieldLabel(source)}
                                        choices={values.map((value) => ({ id: value, name: formatChoiceText(value) }))}
                                        validate={validate}
                                        translateChoice={false}
                                        fullWidth
                                    />
                                );
                            })}
                        </DialogContent>
                        <DialogActions>
                            {closeButton}
                            <Button variant="contained" size="small" onClick={formProps.handleSubmit}>
                                {translate('ra.action.confirm')}
                            </Button>
                        </DialogActions>
                    </RecordContextProvider>
                );
            }}
        </Form>
    );
};

const ReferenceInput = ({
    source,
    field,
    ids,
    validate,
}: {
    source: string;
    field: ReferenceField;
    ids: Iri[];
    validate?: Validator;
}) => {
    const getFieldName = useGetFieldName();
    const isArrayReference = field.maxCardinality !== 1;
    const Input = isArrayReference ? ReferenceArrayInput : RaReferenceInput;

    return (
        <Input
            reference={field.reference.name}
            source={source}
            filter={{ id: ids }}
            fullWidth
            validate={validate}
            allowEmpty
        >
            <SelectInput optionText={getFieldName(field.reference)} translateChoice={false} />
        </Input>
    );
};

const useSuggestions = (suggestSource: string) => {
    const {
        input: { value: formSuggestValue },
    } = useField(suggestSource, { subscription: { value: true } });
    const [suggestValue, setSuggestValue] = useState('');
    // Reload suggestions when locale changes
    const locale = useLocale();

    useEffect(() => {
        // Delay setting suggestValue to prevent unnecessary requests
        const timer = setTimeout(() => {
            setSuggestValue(formSuggestValue);
        }, 500);
        return () => clearTimeout(timer);
    }, [formSuggestValue]);

    const { data, loading } = useFetchGet<Suggestion[]>(
        '/api/reclamations/suggest',
        { [suggestSource]: suggestValue, locale },
        { enabled: suggestValue.length >= 2 },
    );

    return { loading, suggestions: data || [] };
};

const useSuggestCloneHandler = () => {
    const form = useForm();

    return (record: Partial<Reclamation>) => {
        const clone = cloneReclamation(record);
        // !!! We don't want to change a parent errand when cloning reclamation
        delete clone['errand'];

        form.batch(() => {
            for (const [field, value] of Object.entries(clone)) {
                form.change(field, value);
            }
        });
    };
};

export default forwardRef(SuggestCloneIconButton);

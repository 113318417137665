import { ChangeEvent, ComponentType, useState } from 'react';
import {
    Button,
    submitErrorsMutators,
    useListContext,
    useNotify,
    useRefresh,
    useResourceContext,
    useTranslate,
    useUpdateMany,
} from 'react-admin';
import { Box, Dialog, DialogActions, DialogContent as MuiDialogContent, DialogTitle } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Alert } from '@material-ui/lab';
import { Field } from '@api-platform/api-doc-parser';
import arrayMutators from 'final-form-arrays';

import ContentCreate from '@material-ui/icons/Create';
import ContentSave from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import ResourceFieldsSelect from '@components/shared/ResourceFieldsSelect';
import LoadingButton from '@components/button/LoadingButton';
import InputGuesser from '@components/form/InputGuesser';

import useFormFields from '@js/hooks/useFormFields';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { Iri } from '@js/interfaces/ApiRecord';

type Props = {
    resource?: string;
    selectedIds?: Iri[];
    inputComponent?: ComponentType<{ source: string; field: Field; fullWidth?: boolean }>;
};

const STORAGE_KEY = 'app/resource_batch_edit_columns';
const getResourceStorageKey = (resource: string) => `${STORAGE_KEY}/${resource}`;

const mutators = { ...arrayMutators, ...submitErrorsMutators };

const getFromStoreOrDefault = (resource: string) => {
    const serializedState = window.localStorage.getItem(getResourceStorageKey(resource));

    if (serializedState) {
        try {
            return JSON.parse(serializedState) as string[];
        } catch (error) {}
    }

    return [] as string[];
};

const saveInStore = (state: string[], resource: string) => {
    window.localStorage.setItem(getResourceStorageKey(resource), JSON.stringify(state));
};

const BulkEditButton = ({ inputComponent, ...props }: Props) => {
    const resource = useResourceContext(props);
    const { selectedIds, onUnselectItems } = useListContext(props);
    const translate = useTranslate();
    const isSmallScreen = useIsSmallScreen();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button label="ra.action.edit" onClick={handleOpen}>
                <ContentCreate />
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="xl" fullScreen={isSmallScreen} fullWidth>
                <DialogTitle>{translate('ra.action.edit')}</DialogTitle>
                {!selectedIds?.length ? (
                    <Box m={2}>
                        <Alert severity="warning">No selected items</Alert>
                    </Box>
                ) : (
                    <DialogContent
                        resource={resource}
                        onClose={handleClose}
                        selectedIds={selectedIds as string[]}
                        onUnselectItems={onUnselectItems}
                        inputComponent={inputComponent}
                    />
                )}
            </Dialog>
        </>
    );
};

const DialogContent = ({
    resource,
    onClose,
    selectedIds,
    onUnselectItems,
    inputComponent: InputComponent = InputGuesser,
}: {
    resource: string;
    onClose: () => void;
    selectedIds: Iri[];
    onUnselectItems: () => void;
    inputComponent: Props['inputComponent'];
}) => {
    const [selected, setSelected] = useState<string[]>(() => getFromStoreOrDefault(resource));
    const [showSelectFields, setShowSelectFields] = useState(selected.length === 0);
    const fields = useFormFields({ resource }, ['material', 'printLabel']);

    const handleFieldsSelect = (fields: string[]) => {
        setSelected(fields);
        saveInStore(fields, resource);
    };

    return (
        <>
            {showSelectFields ? (
                <FieldsSelect
                    selected={selected}
                    fields={fields}
                    resource={resource}
                    onClose={onClose}
                    onFieldsSelect={handleFieldsSelect}
                    onSelectDone={() => setShowSelectFields(false)}
                />
            ) : (
                <EditForm
                    fields={fields.filter((f) => selected.includes(f.name))}
                    onClose={onClose}
                    resource={resource}
                    selectedIds={selectedIds}
                    onUnselectItems={onUnselectItems}
                    onEditFields={() => setShowSelectFields(true)}
                    inputComponent={InputComponent}
                />
            )}
        </>
    );
};

const EditForm = ({
    fields,
    onClose,
    selectedIds,
    onUnselectItems,
    onEditFields,
    resource,
    inputComponent: InputComponent = InputGuesser,
}: {
    fields: Field[];
    onClose: () => void;
    selectedIds: Iri[];
    onUnselectItems: () => void;
    onEditFields: () => void;
    resource: string;
    inputComponent: Props['inputComponent'];
}) => {
    const [updateMany, { loading }] = useUpdateMany();
    const notify = useNotify();
    const notifyError = useNotifyHttpError();
    const refresh = useRefresh();

    const handleSubmit = (data: Record<string, any>) => {
        updateMany(resource, selectedIds, data, {
            onSuccess: () => {
                notify('ra.notification.updated', {
                    type: 'info',
                    messageArgs: {
                        smart_count: selectedIds.length,
                    },
                    undoable: false,
                });
                onClose();
                onUnselectItems();
                refresh();
            },
            onFailure: (error) => {
                notifyError(error);
                onClose();
                onUnselectItems();
                refresh();
            },
            mutationMode: 'pessimistic',
        });
    };

    return (
        <Form onSubmit={handleSubmit} mutators={mutators}>
            {({ handleSubmit, pristine }) => (
                <>
                    <MuiDialogContent>
                        <Box display="flex" justifyContent="center" flexDirection="column">
                            {fields.map((field) => {
                                return <InputComponent key={field.name} field={field} source={field.name} fullWidth />;
                            })}
                        </Box>
                    </MuiDialogContent>
                    <DialogActions>
                        <Button label="ra.action.cancel" color="default" size="small" onClick={onClose}>
                            <CancelIcon />
                        </Button>
                        <Button label="app.message.select_fields" color="default" size="small" onClick={onEditFields}>
                            <ContentCreate />
                        </Button>
                        <LoadingButton
                            label="ra.action.save"
                            loading={loading}
                            icon={<ContentSave />}
                            onClick={handleSubmit}
                            disabled={pristine}
                        />
                    </DialogActions>
                </>
            )}
        </Form>
    );
};

const FieldsSelect = ({
    selected,
    fields,
    onClose,
    onFieldsSelect,
    resource,
    onSelectDone,
}: {
    selected: string[];
    fields: Field[];
    onClose: () => void;
    onFieldsSelect: (fields: string[]) => void;
    onSelectDone: () => void;
    resource: string;
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newSelected = event.target.checked
            ? [...selected, event.target.name]
            : selected.filter((name) => name !== event.target.name);

        onFieldsSelect(newSelected);
    };

    return (
        <>
            <MuiDialogContent>
                <ResourceFieldsSelect
                    fields={fields}
                    checked={(field) => selected.includes(field.name)}
                    onChange={handleChange}
                    resource={resource}
                />
            </MuiDialogContent>
            <DialogActions>
                <Button label="ra.action.cancel" color="default" size="small" onClick={onClose}>
                    <CancelIcon />
                </Button>
                <Button label="ra.action.edit" onClick={onSelectDone} disabled={!selected.length}>
                    <ContentCreate />
                </Button>
            </DialogActions>
        </>
    );
};

export default BulkEditButton;

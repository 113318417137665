import { FC, ReactNode } from 'react';
import {
    Confirm,
    Record,
    useDeleteWithConfirmController,
    useGetResourceLabel,
    useRecordContext,
    useResourceContext,
} from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';

import IconButton from '@components/button/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

type Props = {
    label?: string;
    resource?: string;
    basePath?: string;
    onClick?: () => void;
    record?: Record;
    confirmContent?: ReactNode;
    disabledReason?: string | null;
    onSuccess?: () => void;
};

const useStyles = makeStyles((theme) => ({
    deleteButton: {
        '& button:not(.Mui-disabled)': {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
}));

const DeleteIconButton: FC<Props> = ({
    basePath,
    onClick,
    confirmContent = 'ra.message.delete_content',
    disabledReason,
    onSuccess,
    ...props
}) => {
    const resource = useResourceContext(props);
    const record = useRecordContext(props);
    const getResourceLabel = useGetResourceLabel();
    const classes = useStyles();

    const { open, loading, handleDialogOpen, handleDialogClose, handleDelete } = useDeleteWithConfirmController({
        record,
        redirect: false,
        mutationMode: 'pessimistic',
        resource,
        onClick,
        basePath,
        onSuccess,
    });

    return (
        <>
            <div className={classes.deleteButton}>
                <IconButton label="ra.action.delete" onClick={handleDialogOpen} disabledReason={disabledReason}>
                    <DeleteIcon />
                </IconButton>
            </div>
            <Confirm
                isOpen={open}
                loading={loading}
                title="ra.message.delete_title"
                content={confirmContent}
                translateOptions={{
                    name: getResourceLabel(resource),
                    id: record?.id,
                }}
                onConfirm={handleDelete}
                onClose={(e) => {
                    if (onClick) {
                        onClick();
                    }
                    handleDialogClose(e);
                }}
            />
        </>
    );
};

export default DeleteIconButton;

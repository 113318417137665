import { useCallback, useEffect, useRef, useState } from 'react';
import { useVersion } from 'react-admin';
import useDidMountEffect from '@js/hooks/useDidMountEffect';
import useCustomMercureSubscription from '@js/hooks/useCustomMercureSubscription';

/**
 * Checks if the application code has changed
 *
 * It checks for code update by downloading the HTML page and
 * comparing the hash of the response with the hash of the current page.
 */
export const useCheckForApplicationUpdate = () => {
    const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
    const currentHash = useRef<number>();
    const version = useVersion();
    const subscribe = useCustomMercureSubscription();

    // 30 minutes
    const delay = 1000 * 60 * 30;
    const disabled = process.env.NODE_ENV !== 'production';

    // Subscribe for new versions
    useEffect(() => {
        return subscribe('/api/app_update_deploy', () => {
            setIsNewVersionAvailable(true);
        });
    }, [subscribe]);

    const checkForNewVersion = useCallback(() => {
        getHashForUrl(URL)
            .then((hash) => {
                if (hash != null && currentHash.current !== hash) {
                    currentHash.current = hash;
                    setIsNewVersionAvailable(true);
                }
            })
            .catch(() => {
                // Ignore errors to avoid issues when connectivity is lost
            });
    }, []);

    // Save the hash of the current page on mount
    useEffect(() => {
        if (disabled) return;

        getHashForUrl(URL).then((hash) => {
            if (hash != null) {
                currentHash.current = hash;
            }
        });
    }, [disabled]);

    // Check for a new version on refresh
    useDidMountEffect(() => {
        if (disabled) return;

        checkForNewVersion();
    }, [version, checkForNewVersion, disabled]);

    // Check for a new version using an interval
    useEffect(() => {
        if (disabled) return;

        const interval = setInterval(checkForNewVersion, delay);
        return () => clearInterval(interval);
    }, [delay, disabled, checkForNewVersion]);

    return isNewVersionAvailable;
};

const URL = window.location.origin + window.location.pathname;

const getHashForUrl = async (url: string) => {
    try {
        const response = await fetch(url);
        if (!response.ok) return null;

        const text = await response.text();
        return hash(text);
    } catch (e) {
        return null;
    }
};

// Simple hash function, taken from https://stackoverflow.com/a/52171480/3723993, suggested by Copilot
const hash = (value: string, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < value.length; i++) {
        ch = value.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

import { useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';

import { ErrandsExport } from '@js/interfaces/ErrandsExport';

const FilenameField = (props: { record?: ErrandsExport }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    if (!record) return null;

    const error = record.status === 'failed';

    return (
        <Typography component="span" variant="body2" color={error ? 'error' : undefined}>
            {error ? translate('ra.page.error') : record.filename}
        </Typography>
    );
};

FilenameField.defaultProps = { source: 'path', sortable: false };

export default FilenameField;

import { cloneDeep, omit } from 'lodash';

import { EmbeddedRecord } from '@js/interfaces/ApiRecord';
import { Reclamation } from '@js/interfaces/reclamation';
import { Errand } from '@js/interfaces/errand';

const excludeDefaultFields = ['id', '@id', '@type', '@context', 'createdAt', 'updatedAt', 'deletedAt'];

export const cloneRecord = <T extends Record<string, any>>(
    record: T,
    { exclude = [], cloneEmbedded = [] }: { exclude?: (keyof T)[]; cloneEmbedded?: (keyof T)[] } = {},
) => {
    const copy = cloneDeep(omit(record, [...exclude, ...cloneEmbedded, ...excludeDefaultFields])) as Partial<T>;
    cloneEmbedded.forEach((field) => {
        const embeddedRecords = record[field];
        if (!Array.isArray(embeddedRecords)) return;

        copy[field] = (embeddedRecords as EmbeddedRecord[]).map((embeddedObject) =>
            cloneDeep(omit(embeddedObject, excludeDefaultFields)),
        ) as T[keyof T];
    });

    return copy;
};

export const cloneReclamation = (reclamation: Partial<Reclamation>) => {
    return cloneRecord(reclamation, {
        exclude: [
            'overrideResellers',
            'processingTimes',
            'selloProductId',
            'selloSynced',
            'selloSyncedAt',
            'composedIdentifier',
            'externalIri',
            'externalApp',
            'splitQuantityLeft',
            'split',
            'parent',
            'child',
            'purchasePrice',
        ],
        cloneEmbedded: ['links'],
    });
};

export const cloneErrand = (errand: Partial<Errand>) => {
    return cloneRecord(errand!, {
        exclude: ['images', 'statusNotifications', 'processingTimes'],
        cloneEmbedded: ['links'],
    });
};

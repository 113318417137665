import { useState } from 'react';
import { Title, useGetResourceLabel, useTranslate } from 'react-admin';
import { Box, Card, CardContent, Grid } from '@material-ui/core';

import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';

import FilterForm from '../FilterForm';
import DateRangeInput from '../input/DateRangeInput';
import EconomicalValueList from './EconomicalValueList';
import CategoryEconomicalStatisticList from './CategoryEconomicalStatisticList';
import SaleChannelsStatistics from './SaleChannelsStatistics';

export type Filter = {
    store?: string;
    after?: string;
    before?: string;
};

const EconomicalDetailed = () => {
    const translate = useTranslate();

    return (
        <>
            <Title title={translate('app.dashboard.section.economical_detailed')} />
            <Card>
                <CardContent>
                    <TopEconomicalStats />
                    <Grid container spacing={4}>
                        <Grid item sm={12}>
                            <SaleChannelsStatistics />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

const TopEconomicalStats = () => {
    const [filter, setFilter] = useState<Filter>();
    const getResourceLabel = useGetResourceLabel();

    return (
        <>
            <FilterForm initialValues={filter} onChange={setFilter}>
                <Box display="flex" alignItems="center" gridGap="20px">
                    <HierarchicalAutocompleteSelectInput
                        label={getResourceLabel('stores')}
                        reference="stores"
                        source="store"
                        variant="standard"
                    />
                    <DateRangeInput />
                </Box>
            </FilterForm>
            <Grid container spacing={4}>
                <Grid item sm={12} md={4} lg={3}>
                    <EconomicalValueList filter={filter} />
                </Grid>
                <Grid item sm={12} md={8} lg={9}>
                    <CategoryEconomicalStatisticList filter={filter} />
                </Grid>
            </Grid>
        </>
    );
};

export default EconomicalDetailed;

import { InputProps, minValue, Record as RaRecord, required, TextInput, useTranslate } from 'react-admin';
import { useField } from 'react-final-form';

import { minutes } from '@js/validator/minutes';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { minutesStringToSeconds, secondsToMinutesSting } from '@js/utility/numberFormatUtility';
import useWarningInputStyle from '@components/resource/reclamation/input/useWarningInputStyle';

type Props = {
    source: string;
    transformIntoSeconds: boolean;
    resource?: string;
    record?: RaRecord;
    label?: string | false;
} & Partial<Pick<InputProps, 'variant' | 'parse' | 'format' | 'fullWidth' | 'className' | 'helperText'>>;

const TimeInput = ({ transformIntoSeconds, className, helperText, ...props }: Props) => {
    const getFieldLabel = useTranslateResourceField(props.resource);
    const warningClass = useWarningInputStyle();
    const translate = useTranslate();

    const {
        input: { value },
    } = useField(props.source, { subscription: { value: true } });

    // Minutes or seconds
    let finalValue = value ? parseInt(value) : 0;
    // If the value is in seconds, transform it to minutes
    if (transformIntoSeconds) {
        finalValue = finalValue / 60;
    }

    const hours = Math.floor(finalValue / 60);
    const warningAfterHours = 4;
    const showWarning = hours > warningAfterHours;

    return (
        <TextInput
            type="number"
            label={getFieldLabel('timePlaceholder')}
            autoComplete="off"
            validate={[minutes(), minValue(1), required()]}
            {...props}
            {...(transformIntoSeconds && { format, parse })}
            helperText={
                showWarning
                    ? translate('app.message.time_tracked_to_big_warning', { hours: warningAfterHours })
                    : helperText
            }
            className={showWarning ? `${warningClass} ${className ?? ''}`.trim() : className}
        />
    );
};

const format = (value: string) => {
    const seconds = parseInt(value, 10);
    return isNaN(seconds) ? '' : secondsToMinutesSting(seconds);
};

const parse = (value: string) => (value ? minutesStringToSeconds(value) : null);

export default TimeInput;

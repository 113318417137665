import { ComponentType, createElement, FC, useCallback, useRef } from 'react';
import { MenuItemLink as RaMenuItemLink, useGetResourceLabel, useNotify } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';

import useFetchGet from '@js/hooks/useFetchGet';
import useGetIdentity from '@js/hooks/useGetIdentity';
import { useEntrypoint } from '@js/context/AppConfigContext';
import useSubscribeUserRelatedRecords from '@js/hooks/useSubscribeUserRelatedRecords';
import useOnNewNotificationSubscription from '@components/resource/user_notifications/useOnNewNotificationSubscription';

import { ErrandsExport } from '@js/interfaces/ErrandsExport';
import { UserNotification } from '@js/interfaces/usernotification';

interface MenuItemProps {
    resource: string;
    label: string;
    icon?: ComponentType;
}

const MenuItem: FC<{ resource: { name: string; icon?: ComponentType } }> = ({ resource: { name, icon } }) => {
    const getResourceLabel = useGetResourceLabel();
    const commonProps = {
        label: getResourceLabel(name),
        resource: name,
        icon,
    };

    switch (name) {
        case 'user_notifications':
            return <UserNotificationsMenuItem {...commonProps} />;
        case 'errands_exports':
            return <ErrandsExportsMenuItem {...commonProps} />;
        default:
            return <MenuItemLink {...commonProps} />;
    }
};

const MenuItemLink: FC<MenuItemProps> = ({ label, resource, icon }) => {
    return <RaMenuItemLink to={`/${resource}`} primaryText={label} leftIcon={createElement(icon ?? DefaultIcon)} />;
};

const UserNotificationsMenuItem: FC<MenuItemProps> = ({ label, ...props }) => {
    const { identity } = useGetIdentity();
    const { data, refresh } = useFetchGet<{ count: number }>(`${identity?.id}/count_unread_notifications`, undefined, {
        enabled: !!identity,
    });
    const notify = useNotify();

    const onNewNotification = useCallback(
        (notification: UserNotification) => {
            if (!notification.readAt) {
                refresh();
                notify('app.message.received_new_message');
            }
        },
        [refresh, notify],
    );

    useOnNewNotificationSubscription(onNewNotification);

    return <MenuItemLink {...props} label={`${label} (${data?.count ?? '--'})`} />;
};

const ErrandsExportsMenuItem: FC<MenuItemProps> = ({ label, ...props }) => {
    const entrypoint = useEntrypoint();
    const { data, refresh } = useFetchGet<{ count: number }>(`${entrypoint}/errands_exports/count_waiting`);
    const notify = useNotify();
    const cacheNotifiedExportsRef = useRef<string[]>([]);

    const onNewExport = useCallback(
        (errandsExport) => {
            const { '@id': id, status } = errandsExport as ErrandsExport;

            if (status === 'completed' && !cacheNotifiedExportsRef.current.includes(id)) {
                cacheNotifiedExportsRef.current.push(id);

                refresh();
                notify('app.message.export_completed');
            }
        },
        [refresh, notify],
    );

    useSubscribeUserRelatedRecords(onNewExport, 'errands_exports');

    return <MenuItemLink {...props} label={`${label} (${data?.count ?? '--'})`} />;
};

export default MenuItem;

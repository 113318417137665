import { Record as RaRecord, useRecordContext } from 'react-admin';
import { Reclamation } from '@js/interfaces/reclamation';

const useIsCopiedFieldFromSavingsCalculation = ({ source, ...props }: { source: string; record?: RaRecord }) => {
    const record = useRecordContext(props) as Reclamation | undefined;

    return record?.copiedFieldsFromSavingsCalculation?.includes(source) ?? false;
};

export default useIsCopiedFieldFromSavingsCalculation;

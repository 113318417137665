import {
    FieldTitle,
    minValue,
    Record as RaRecord,
    useInput,
    useTranslate,
    ValidationError,
    Validator,
} from 'react-admin';
import { TextField } from '@material-ui/core';
import useInputAutoValidator from '@js/hooks/useInputAutoValidator';

type Props = {
    source?: string;
    record?: RaRecord;
    resource?: string;
    fullWidth?: boolean;
    warningAfter?: number;
    step?: string;
    validate?: Validator | Validator[];
    label?: string | false;
    warningClassName: string;
    fieldValueCopiedFromCo2: boolean;
};

const convertStringToNumber = (value: string) => {
    const float = parseFloat(value);

    return isNaN(float) ? null : float;
};

const WeightInput = ({
    label,
    validate,
    warningClassName,
    fieldValueCopiedFromCo2,
    warningAfter = 1000,
    step = '0.01',
    source = 'weight',
    ...props
}: Props) => {
    const min = 0.01;

    const finalValidate = useInputAutoValidator({ source, validate }) ?? [];
    finalValidate.push(minValue(min));

    const {
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        ...props,
        source,
        step,
        validate: finalValidate,
        parse: convertStringToNumber,
        type: 'number',
        min,
    });
    const translate = useTranslate();

    const showWarning = input.value > warningAfter;
    const hasError = !!(touched && (error || submitError));

    return (
        <TextField
            type="number"
            label={
                label !== '' &&
                label !== false && (
                    <FieldTitle label={label} source={source} resource={props.resource} isRequired={isRequired} />
                )
            }
            className={showWarning || fieldValueCopiedFromCo2 ? warningClassName : undefined}
            variant="filled"
            margin="dense"
            error={hasError}
            helperText={
                hasError ? (
                    <ValidationError error={error} />
                ) : (
                    <>
                        {translate('app.form.helperText.weight_in_kg')}
                        {fieldValueCopiedFromCo2 && (
                            <>
                                <br />
                                {translate('app.form.helperText.copied_field_from_savings_calculation')}
                            </>
                        )}
                        {showWarning && (
                            <>
                                <br />
                                {translate('app.form.helperText.too_big_weight')}
                            </>
                        )}
                    </>
                )
            }
            fullWidth={props.fullWidth}
            inputProps={{ step, min }}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            {...input}
        />
    );
};

export default WeightInput;

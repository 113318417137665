import { useRecordContext } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core';

import CircularProgressWithLabel from '@components/mui/CircularProgressWithLabel';
import { ErrandsExport } from '@js/interfaces/ErrandsExport';

const ProgressCircularField = (props: { record?: ErrandsExport; resource?: string }) => {
    const record = useRecordContext(props);
    const classes = useStyles({ record });

    return <CircularProgressWithLabel value={record?.progress ?? 0} className={classes.root} />;
};

const useStyles = makeStyles<Theme, { record?: ErrandsExport }>((theme) => ({
    root: {
        color: ({ record = {} }) => {
            const palette = theme.palette;
            const { status } = record;

            return status === 'completed'
                ? palette.secondary.main
                : status === 'failed'
                ? palette.error.main
                : palette.primary.main;
        },
    },
}));

ProgressCircularField.defaultProps = { sortable: false, source: 'progress' };

export default ProgressCircularField;

import { LinearProgress, useGetManyReference, useRecordContext, useResourceContext } from 'react-admin';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { toLocaleString } from '@js/utility/dateUtility';

import { Iri } from '@js/interfaces/ApiRecord';
import { Reclamation } from '@js/interfaces/reclamation';
import { GetManyReferenceResult } from '@components/types';

type StatusHistoryItem = { id: Iri; createdAt?: string; label?: string };

const StatusesHistory = ({
    reference,
    target,
    ...props
}: {
    reference: string;
    target: string;
    record?: Reclamation;
    resource?: string;
}) => {
    const record = useRecordContext(props) ?? {};
    const resource = useResourceContext(props);
    const { data, ids, loaded } = useGetManyReference(
        reference,
        target,
        record.id,
        { page: 1, perPage: 99 },
        { field: 'createdAt', order: 'DESC' },
        {},
        resource,
    ) as GetManyReferenceResult<StatusHistoryItem>;

    if (!loaded) return <LinearProgress />;

    const items = ids.map((id) => data[id]).filter(Boolean);

    return (
        <Timeline>
            {items.map((item, index) => {
                const isFirst = index === 0;

                return (
                    <TimelineItem key={item.id}>
                        <TimelineOppositeContent>
                            <Typography color="textSecondary">{toLocaleString(item.createdAt)}</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color={isFirst ? 'primary' : undefined} />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant={isFirst ? 'subtitle2' : 'body2'}>{item.label}</Typography>
                        </TimelineContent>
                    </TimelineItem>
                );
            })}
        </Timeline>
    );
};

export default StatusesHistory;

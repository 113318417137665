import { Edit, EditProps } from 'react-admin';
import { FC } from 'react';

import LockedFormSafeguard from '@components/form/LockedFormSafeguard';
import ProductForm from './ProductForm';

const ProductEdit: FC<EditProps> = (props) => {
    return (
        <LockedFormSafeguard {...props}>
            <Edit {...props}>
                <ProductForm />
            </Edit>
        </LockedFormSafeguard>
    );
};

export default ProductEdit;

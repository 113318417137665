import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => {
    const warningColor = theme.palette.warning.dark;

    return {
        warning: {
            '& .MuiFormLabel-root': {
                color: warningColor,
            },
            '& .MuiFormHelperText-root': {
                color: warningColor,
            },
            '& .MuiFilledInput-underline:after': {
                borderBottomColor: warningColor,
                transform: 'scaleX(1)',
            },
            '& .MuiInputBase-input': {
                color: warningColor,
            },
        },
    };
});

const useWarningInputStyle = () => {
    return useStyle().warning;
};

export default useWarningInputStyle;

import { ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import { useTranslate } from 'react-admin';

interface Props {
    title: string;
    loading?: boolean;
    onClose: () => void;
    onExited?: () => void;
    open: boolean;
    children: ReactNode;
}

const DialogCodeScanner = ({ open, title, onClose, loading, children, onExited }: Props) => {
    const translate = useTranslate();

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby={title} TransitionProps={{ onExited }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {loading && <LinearProgress />}
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate('ra.action.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCodeScanner;

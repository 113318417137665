import { ComponentType } from 'react';
import { Box } from '@material-ui/core';

import LockRecordIcon from '@components/detail/LockRecordIcon';
import ShowIconButton from '@components/button/ShowIconButton';
import EditIconButton from '@components/button/EditIconButton';
import DeleteIconButton from '@components/button/DeleteIconButton';

import { useResourceContext, useResourceDefinition } from 'react-admin';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { ApiRecord } from '@js/interfaces/ApiRecord';

interface Props {
    record?: ApiRecord;
    resource?: string;
    hasShow?: boolean;
    hasEdit?: boolean;
    hasDelete?: boolean;
    showButton?: ComponentType;
    editButton?: ComponentType;
    deleteButton?: ComponentType;
    basePath?: string;
    showLockIcon?: boolean;
}

const RowIconActions = ({ showLockIcon, hasDelete, ...props }: Props) => {
    const { hasShow, hasEdit } = useResourceDefinition(props);
    const resource = useResourceContext(props);
    const permissions = useUserPermissions();

    const finalHasDelete = hasDelete !== undefined ? hasDelete : permissions.isEditActionEnabled(resource, 'delete');

    return (
        <Box display="flex" justifyContent="end">
            {showLockIcon && <LockRecordIcon />}
            {hasShow && <ShowIconButton {...props} />}
            {hasEdit && <EditIconButton {...props} />}
            {finalHasDelete && <DeleteIconButton {...props} />}
        </Box>
    );
};

export default RowIconActions;

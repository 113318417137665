import { useEffect } from 'react';

import useGetIdentity from '@js/hooks/useGetIdentity';
import useCustomMercureSubscription from '@js/hooks/useCustomMercureSubscription';

import { Iri } from '@js/interfaces/ApiRecord';

const useSubscribeUserRelatedRecords = <T extends { '@id': Iri; [key: string]: any }>(
    onRecordUpdate: (record: T) => void,
    uri: string,
) => {
    const { identity } = useGetIdentity();
    const subscription = useCustomMercureSubscription();

    useEffect(() => {
        if (!identity?.id) return;

        return subscription(`${identity.id}/${uri}`, (data) => {
            try {
                const record = JSON.parse(data);
                onRecordUpdate(record as T);
            } catch (e) {
                console.error('Failed to parse notification data', e);
            }
        });
    }, [subscription, identity?.id, onRecordUpdate, uri]);
};

export default useSubscribeUserRelatedRecords;

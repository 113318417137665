import { FC } from 'react';
import { AppBar as RaAppBar, AppBarProps, useTranslate } from 'react-admin';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import UserMenu from './UserMenu';
import LanguageMenu from './LanguageMenu';
import CustomNotification from './CustomNotification';
import CheckForApplicationUpdate from './CheckForApplicationUpdate';

import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

const useStyles = makeStyles<Theme, { isAdmin: boolean }>({
    root: ({ isAdmin }) => ({
        '& #header-language-menu + button, #header-language-menu + .MuiCircularProgress-root': {
            display: isAdmin ? 'block' : 'none',
        },
    }),
    appTitle: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    },
});

const AppBar: FC<AppBarProps> = (props) => {
    const { isAdmin } = useUserPermissions();
    const isSmallScreen = useIsSmallScreen();
    const trans = useTranslate();
    const classes = useStyles({ isAdmin });

    return (
        <>
            <CustomNotification />
            <RaAppBar {...props} userMenu={<UserMenu />} className={classes.root}>
                <Box display="flex" flex="1" gridGap="16px">
                    <Typography id="react-admin-title" variant="h6" color="inherit" noWrap />
                    <CheckForApplicationUpdate />
                </Box>
                {!isSmallScreen && <strong className={classes.appTitle}>{trans('app.title')}</strong>}
                <LanguageMenu />
            </RaAppBar>
        </>
    );
};

export default AppBar;

import { forwardRef, MouseEventHandler, ReactNode, useImperativeHandle, useState } from 'react';
import { Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import IconButton from '@components/button/IconButton';

interface Props {
    children: ReactNode;
    keepMounted?: boolean;
    label?: string;
}

export interface MenuHandle {
    close: () => void;
}

const MenuButton = forwardRef<MenuHandle, Props>(({ children, keepMounted, label = 'app.action.more' }, ref) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useImperativeHandle(ref, () => ({
        close: () => {
            setAnchorEl(null);
        },
    }));

    const handleOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton label={label} onClick={handleOpen}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose} keepMounted={keepMounted}>
                {children}
            </Menu>
        </div>
    );
});

MenuButton.displayName = 'MenuButton';

export default MenuButton;

import { DateField, ListProps, ReferenceFieldProps, useRecordContext } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Chip } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import get from 'lodash/get';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import RaReferenceField from '@components/field/ReferenceField';
import FunctionField from '@components/field/FunctionField';
import RowIconActions from '@components/list/RowIconActions';

import { secondsToHumanTime } from '@js/utility/numberFormatUtility';
import useResourceSchema from '@js/hooks/useResourceSchema';
import useFilters from './useFilters';

import { ProcessingTime } from '@js/interfaces/processingtime';
import { Errand } from '@js/interfaces/errand';
import { Reclamation } from '@js/interfaces/reclamation';

const ProcessingTimeList = (props: ListProps) => {
    const filters = useFilters();

    return (
        <ListGuesser {...props} filters={filters}>
            <DatagridGuesser rowActions={<RowIconActions />}>
                <DateField source="createdAt" showTime />
                <ProcessingTimeField source="time" />
                <ReferenceField source="errand" />
                <ReferenceField source="reclamation" />
            </DatagridGuesser>
        </ListGuesser>
    );
};

const ProcessingTimeField = (props: { source: string; record?: ProcessingTime }) => {
    const record = useRecordContext(props);

    return <span>{`${secondsToHumanTime(record?.time)} h`}</span>;
};

const ReferenceField = (props: Omit<ReferenceFieldProps, 'children' | 'reference'>) => {
    const schema = useResourceSchema(props);
    const field = schema.fields?.find((field) => field.name === props.source);

    if (field?.reference !== null && typeof field?.reference === 'object' && field.maxCardinality === 1) {
        const getLabel = (record: Errand | Reclamation) => {
            const label = get(record, 'name') || get(record, 'label');

            if (label) {
                return `${label} (${record.composedIdentifier})`;
            }
            return record.composedIdentifier;
        };

        return (
            <RaReferenceField {...props} reference={field.reference.name}>
                <FunctionField<Errand | Reclamation> render={(record) => <StyledChip label={getLabel(record)} />} />
            </RaReferenceField>
        );
    }

    return <Alert severity="warning">Invalid field</Alert>;
};

const StyledChip = styled(Chip)({
    cursor: 'inherit',
    color: 'inherit',
});

export default ProcessingTimeList;

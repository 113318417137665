import {
    AutocompleteArrayInput,
    AutocompleteInput,
    AutocompleteInputProps,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    SortPayload,
    useCreate,
    useCreateSuggestionContext,
    useTranslate,
} from 'react-admin';
import { FC, FormEventHandler, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { Field } from '@api-platform/api-doc-parser';
import SaveIcon from '@material-ui/icons/Save';

import LoadingButton from '@components/button/LoadingButton';

import useInputReferenceField from '@js/hooks/useInputReferenceField';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

export type AutocompleteGuesserInputProps = {
    source: string;
    resource?: string;
    multiple?: boolean;
    reference?: string;
    field?: Field;
    label?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    sort?: SortPayload;
    filter?: Record<string, any>;
    filterToQuery?: (searchText: string) => { q: string | undefined };
    perPage?: number;
    optionText?: AutocompleteInputProps['optionText'];
    inputText?: AutocompleteInputProps['inputText'];
    validate?: AutocompleteInputProps['validate'];
    matchSuggestion?: AutocompleteInputProps['matchSuggestion'];
    InputProps?: AutocompleteInputProps['InputProps'];
    variant?: 'standard' | 'outlined' | 'filled';
    helperText?: string | false;
    alwaysOn?: boolean;
};

type CreateProps = {
    resource: string;
    fieldName: string;
};

/**
 * All suggestions are matched by default because it's loaded from the API.
 * If api return only lets say users with option text "John Doe",
 * but searched by email "test@mail.com" by default, AutoCompleteInput will not show it.
 */
const defaultMatchSuggestion = () => true;

const AutoCompleteGuesserInput = (props: AutocompleteGuesserInputProps) => {
    const { reference, fieldName, multiple } = useInputReferenceField(props);

    return <AutoCompleteInput {...props} reference={reference} multiple={multiple} fieldName={fieldName} />;
};

const AutoCompleteInput = ({
    reference,
    fullWidth,
    optionText,
    inputText,
    disabled,
    allowCreate,
    multiple,
    helperText,
    fieldName,
    InputProps,
    matchSuggestion = defaultMatchSuggestion,
    ...props
}: Omit<AutocompleteGuesserInputProps, 'field'> & { reference: string; fieldName: string }) => {
    const ReferenceComponent = multiple ? ReferenceArrayInput : ReferenceInput;
    const SelectComponent = multiple
        ? disabled
            ? SelectArrayInput
            : AutocompleteArrayInput
        : disabled
        ? SelectInput
        : AutocompleteInput;

    return (
        <ReferenceComponent
            reference={reference}
            sort={{ field: fieldName, order: 'ASC' }}
            allowEmpty={false}
            {...props}
        >
            <SelectComponent
                optionText={optionText ?? fieldName}
                create={allowCreate ? <Create resource={reference} fieldName={fieldName} /> : undefined}
                disabled={disabled}
                fullWidth={fullWidth}
                helperText={helperText}
                options={{ InputProps }}
                {...(!disabled && { inputText, matchSuggestion, resettable: true })}
            />
        </ReferenceComponent>
    );
};

const Create: FC<CreateProps> = ({ resource, fieldName }) => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = useState(filter || '');
    const [create, { loading }] = useCreate(resource);
    const getFieldLabel = useTranslateResourceField(resource);
    const translate = useTranslate();
    const isSmall = useIsSmallScreen();
    const notifyFailure = useNotifyHttpError();

    const handleSubmit: FormEventHandler<HTMLFormElement | HTMLButtonElement> = (event) => {
        event.preventDefault();
        create(
            {
                payload: {
                    data: {
                        [fieldName]: value,
                    },
                },
            },
            {
                onSuccess: ({ data }: { data: Record<string, any> }) => {
                    setValue('');
                    onCreate(data);
                },
                onFailure: notifyFailure,
            },
        );
    };

    return (
        <Dialog open={true} onClose={onCancel} fullWidth fullScreen={isSmall} maxWidth="xs">
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box my={3}>
                        <TextField
                            label={getFieldLabel(fieldName)}
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                            variant="outlined"
                            autoFocus
                            fullWidth
                            required
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} variant="contained" size="small" disabled={loading}>
                        {translate('ra.action.cancel')}
                    </Button>
                    <LoadingButton
                        label="ra.action.save"
                        loading={loading}
                        icon={<SaveIcon />}
                        onClick={handleSubmit}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AutoCompleteGuesserInput;

import { FC, lazy, Suspense, useState } from 'react';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { Button, makeStyles } from '@material-ui/core';
import { LinearProgress, useListContext, useTranslate } from 'react-admin';

import DialogCodeScanner from '@components/camera/DialogCodeScanner';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

const QrCodeScanner = lazy(() => import('@components/camera/QrCodeScanner'));

interface Props {
    filterName?: string;
    resource?: string;
    alwaysOn?: boolean;
    source: string;
}

const useStyles = makeStyles({
    videoWrap: {
        position: 'relative',
    },
    button: {
        marginBottom: '12px',
    },
    shade: {
        position: 'absolute',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.48)',
        boxSizing: 'border-box',
        inset: '0px',
        '& > div': {
            position: 'absolute',
            backgroundColor: 'rgb(255, 255, 255)',
        },
    },
    horizontalLine: {
        width: '40px',
        height: '5px',
    },
    verticalLine: {
        width: '5px',
        height: '45px',
    },
});

const QrCodeScannerFilter: FC<Props> = ({ resource, source, filterName = 'q' }) => {
    const translateField = useTranslateResourceField();
    const [open, setOpen] = useState(false);

    const { filterValues, setFilters } = useListContext();
    const translate = useTranslate();
    const classes = useStyles();

    const handleSuccess = (result: string) => {
        setFilters(
            {
                ...filterValues,
                [filterName]: result,
            },
            {},
        );
        setOpen(false);
    };

    return (
        <div>
            <Button
                variant="contained"
                color="default"
                size="small"
                className={classes.button}
                onClick={() => setOpen(true)}
                startIcon={<CropFreeIcon />}
            >
                {translateField(source, resource)}
            </Button>
            <DialogCodeScanner
                onClose={() => setOpen(false)}
                open={open}
                title={translate('app.filter.fields.scanQrCode.label')}
            >
                <Suspense fallback={<LinearProgress />}>
                    <QrCodeScanner onSuccess={handleSuccess} />
                </Suspense>
            </DialogCodeScanner>
        </div>
    );
};

export default QrCodeScannerFilter;

import { FC } from 'react';
import { CheckboxGroupInput, CheckboxGroupInputProps } from 'react-admin';

import { ROLES } from '@js/interfaces/user';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

const RolesInput: FC<Omit<CheckboxGroupInputProps, 'choices'>> = (props) => {
    const permissions = useUserPermissions();

    const options = Object.values(ROLES)
        .filter((role) => role !== ROLES.ROLE_USER && !(role === ROLES.ROLE_SUPER_ADMIN && !permissions.isSuperAdmin))
        .map((role) => ({
            id: role,
            name: `resources.users.fields.roleChoices.${role.toLocaleLowerCase()}`,
        }));

    return <CheckboxGroupInput choices={options} {...props} />;
};

RolesInput.defaultProps = {
    source: 'roles',
};

export default RolesInput;
